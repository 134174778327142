import { useMemo } from "react";
import { Box, Fade } from "@chakra-ui/react";
import { AnimatePresence, motion, MotionProps } from "framer-motion";
import { throttle } from "@/utils";
import Slider from "@/components/Slider";
import { useViewer } from "viewer/store/viewer";
import { getCurrentCameraState } from "viewer/store/viewer/selectors";
import FlashlightIcon from "assets/icons/brightness_outlined.svg?react";
import { useViewerActions } from "../MonitoringManager";

const sliderVariants: MotionProps["variants"] = {
  initial: { y: "20%", opacity: 0, transition: { duration: 0.3 } },
  animate: { y: "0%", opacity: 1, transition: { duration: 0.3 } },
  exit: { y: "20%", opacity: 0, transition: { duration: 0.3 } }
};

export default function FlashlightSlider() {
  const { changeFlashlightIntensity } = useViewerActions();
  const cameraState = useViewer(getCurrentCameraState);
  const flashlightAdjustable = cameraState?.flashlightAdjustable;

  const throttledOnChange = useMemo(
    () => throttle((val: number) => changeFlashlightIntensity(val), 500),
    [changeFlashlightIntensity]
  );

  const onChange = (val: number) => throttledOnChange(val);
  const onChangeEnd = (val: number) => changeFlashlightIntensity(val);

  if (!flashlightAdjustable) return null;
  return (
    <Fade in={flashlightAdjustable} unmountOnExit transition={{ exit: { duration: 0.2 }, enter: { duration: 0.2 } }}>
      <AnimatePresence initial={false}>
        <Box
          as={motion.div}
          variants={sliderVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          pos="absolute"
          bottom="68px"
          right="32px"
          zIndex={100}
        >
          <Slider
            value={cameraState?.flashlightIntensity}
            min={0}
            max={1}
            step={0.01}
            onChange={onChange}
            onChangeEnd={onChangeEnd}
            props={{
              orientation: "vertical",
              minH: "16rem",
              defaultValue: cameraState?.flashlightIntensity || 0,
              variant: "wide"
            }}
            thumbChild={<Box as={FlashlightIcon} />}
          />
        </Box>
      </AnimatePresence>
    </Fade>
  );
}
