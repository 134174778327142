import CrashReporter from "@/modules/app/CrashReporter";

export default function checkMonitoringResponse(jid: string, response: StartMonitoringResponse) {
  let error: "no_response" | "missing_camera_state" | "missing_camera_config" | "missing_available_cameras" | null =
    null;

  if (!response) error = "no_response";
  if (!response.cameraState) error = "missing_camera_state";
  if (!response.configuration) error = "missing_camera_config";
  else if (!response.configuration.availableCameras) error = "missing_available_cameras";

  if (error) {
    const message = `Monitoring response from Camera '${jid}' wasn't valid, error: '${error}'`;
    log.err(message);
    if (!isDev) CrashReporter.sendMessage(message);
  }
}
