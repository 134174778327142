import { DarkMode, Flex, Text } from "@chakra-ui/react";
import { t } from "@/utils";
import { useIsConnected } from "@/hooks";
import NoConnection from "@/modules/app/components/NoConnection";
import Icon from "@/components/Icon";
import ErrorIcon from "assets/icons/error.svg?react";

export default function MonitoringError({ cameraError }: { cameraError: boolean }) {
  const { isConnected } = useIsConnected();

  if (!isConnected) return <NoConnection style={{ zIndex: 3, pos: "absolute", top: 122 }} />;
  if (!cameraError) return null;
  return (
    <DarkMode>
      <Flex
        align="center"
        px="12"
        py="8"
        columnGap="12"
        bg="error-container"
        borderRadius="1rem"
        pos="absolute"
        top="122"
        zIndex={4}
      >
        <Icon icon={ErrorIcon} size="20" fill="on-error-container" />
        <Text color="on-error-container">{t("noConnection.camera")}</Text>
      </Flex>
    </DarkMode>
  );
}
