import { AnimatePresence, motion, MotionProps } from "framer-motion";
import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import Arrow from "assets/icons/arrow_down.svg?react";
import Edit from "assets/icons/edit_outlined.svg?react";
import IconButton from "@/components/IconButton";
import Icon from "@/components/Icon";
import { useViewer } from "viewer/store/viewer";
import { t } from "@/utils";
import { useMonitoringPanelButtonStyle, useMonitoringIconButtonStyle } from "../utils/styles";
import { autoHideStyle } from "./videoUIAutoHide";

type Props = {
  items: {
    key: CameraControlKey;
    icon: any;
    labelKey: StringKey;
    action?: Cb;
    Component?: ({ actionController }: { actionController: ActionController }) => JSX.Element | null;
  }[];
  actionStates: ActionStates;
  actionController: ActionController;
  openEditDialog: Cb;
};

const buttonVariants: MotionProps["variants"] = {
  initial: { y: "-20%", opacity: 0, transition: { duration: 0.3 } },
  animate: { y: "0%", opacity: 1, transition: { duration: 0.3 } },
  exit: { y: "-20%", opacity: 0, transition: { duration: 0.3 } }
};
const panelVariants: MotionProps["variants"] = {
  initial: { y: "20%", opacity: 0, transition: { duration: 0.3 } },
  animate: { y: "0%", opacity: 1, transition: { duration: 0.3 } },
  exit: { y: "20%", opacity: 0, transition: { duration: 0.3 } }
};

export default function LeftPanel({ items, actionStates, actionController, openEditDialog }: Props) {
  const { isMonitoringPanelOpened, setIsMonitoringPanelOpened } = useViewer();
  const buttonStyle = useMonitoringIconButtonStyle();

  const { getStyleObjAsValues } = useMonitoringPanelButtonStyle();
  return (
    <Box pos="absolute" left="32" bottom="24" {...autoHideStyle}>
      <Box pos="relative">
        <AnimatePresence initial={false}>
          {isMonitoringPanelOpened && (
            <Flex
              key="panel"
              flexDirection="column"
              alignItems="center"
              rowGap="8px"
              bg={buttonStyle.bg}
              backdropFilter={buttonStyle.props.backdropFilter}
              px="8"
              py="12"
              pos="absolute"
              left="0"
              bottom="0"
              borderRadius="24px"
              as={motion.div}
              variants={panelVariants}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <IconButton
                icon={Edit}
                iconSize="16px"
                {...buttonStyle}
                p="4"
                onClick={openEditDialog}
                tooltip={{ label: t("buttons.edit"), ...autoHideStyle }}
              />
              {items.map((item) => {
                if (!item) return null;
                const { icon, key, labelKey, action, Component } = item;
                const label = t(labelKey);
                const isMultiWorded = label.includes(" ");
                const isActive = actionStates[key].isActive;
                const isDisabled = actionStates[key].isDisabled;
                const isProcessing = actionStates[key].isProcessing;
                const { activeBg, bg, hoverBg } = getStyleObjAsValues(isActive);

                return (
                  <Box pos="relative" key={key}>
                    {Component && <Component actionController={actionController} />}
                    <Flex
                      as="button"
                      disabled={isDisabled}
                      direction="column"
                      align="center"
                      py="8px"
                      px="2px"
                      minW="60px"
                      maxW="60px"
                      bg={isActive ? bg : "transparent"}
                      _hover={{ bg: hoverBg }}
                      _active={{ bg: activeBg }}
                      _disabled={{
                        opacity: 0.4,
                        boxShadow: "none",
                        pointerEvents: "none"
                      }}
                      transition="all 0.2s"
                      borderRadius="1rem"
                      onClick={action}
                    >
                      {isProcessing ? (
                        <Spinner size="md" borderWidth="3px" color="neutral.99" />
                      ) : (
                        <Icon icon={icon} fill="neutral.99" />
                      )}
                      <Text
                        w="100%"
                        variant="label2"
                        align="center"
                        color="white"
                        mt="4px"
                        overflow="hidden"
                        display="-webkit-box"
                        textOverflow="ellipsis"
                        sx={{
                          WebkitLineClamp: isMultiWorded ? 2 : 1,
                          WebkitBoxOrient: "vertical"
                        }}
                      >
                        {label}
                      </Text>
                    </Flex>
                  </Box>
                );
              })}
              <IconButton
                {...buttonStyle}
                onClick={() => setIsMonitoringPanelOpened(false)}
                icon={Arrow}
                p="8"
                tooltip={{ label: t("collapse") }}
              />
            </Flex>
          )}

          {!isMonitoringPanelOpened && (
            <IconButton
              {...buttonStyle}
              icon={Arrow}
              tooltip={{ label: t("expand") }}
              onClick={() => setIsMonitoringPanelOpened(true)}
              props={{
                key: "panel-button",
                as: motion.button,
                // @ts-ignore
                variants: buttonVariants,
                initial: "initial",
                animate: "animate",
                exit: "exit",
                p: "12",
                borderRadius: "12px",
                sx: {
                  svg: { transform: "rotate(180deg)" }
                }
              }}
              iconSize="24"
            />
          )}
        </AnimatePresence>
      </Box>
    </Box>
  );
}
