import Announcer from "@/modules/Announcer";
import { useDevices } from "@/store/devices";
import useSettingsSaveProgress from "@/hooks/useSettingsSaveProgress";
import { updateCameraStationSettings } from "@/modules/cloud/station";
import Customize from "@/shared-steps/Customize";
import type { WizardProps } from "../Wizard";

export default function InitialCustomize({ wizard, state, ...props }: WizardProps<{ jid: string; name: string }>) {
  const { Error, Progress, errorControls, progressControls } = useSettingsSaveProgress();
  const updatePairedDevice = useDevices((store) => store.updatePairedDevice);

  const updateSettings = async (setting: CSTypes.UpdateParams) => {
    progressControls.show();
    const response = await updateCameraStationSettings({
      xmppLogin: state.jid,
      color: setting.color,
      icon: setting.icon,
      name: setting.name
    });
    if (!response) {
      errorControls.show();
      return;
    }
    Announcer.getInstance().announceItemChange("DEVICES");
    updatePairedDevice({ jid: state.jid, ...setting });
    wizard.close();
  };

  return (
    <>
      <Error />
      <Customize
        {...props}
        state={state}
        Progress={Progress}
        cameraSettings={{
          color: "color1",
          icon: "video_camera",
          name: state.name
        }}
        updateSettings={updateSettings}
        config={{
          renderBack: false,
          renderContinue: true,
          title: "personalizeCamera",
          allowSaveWithoutChange: true,
          allowBackOnSave: false,
          onSkip: wizard.close
        }}
      />
    </>
  );
}
