import { Slider as ChakraSlider, SliderTrack, SliderFilledTrack, SliderThumb, SliderProps } from "@chakra-ui/react";

type Props = {
  value?: SliderProps["value"];
  min: SliderProps["min"];
  max: SliderProps["max"];
  step: SliderProps["step"];
  onChange: SliderProps["onChange"];
  onChangeEnd: SliderProps["onChangeEnd"];
  onChangeStart?: SliderProps["onChangeStart"];
  props?: SliderProps;
  thumbChild?: any;
};

export default function Slider({ min, max, step, onChange, onChangeEnd, onChangeStart, props, thumbChild }: Props) {
  return (
    <ChakraSlider
      min={min}
      max={max}
      step={step}
      onChange={onChange}
      onChangeEnd={onChangeEnd}
      onChangeStart={onChangeStart}
      {...props}
    >
      <SliderTrack>
        <SliderFilledTrack />
      </SliderTrack>
      <SliderThumb>{thumbChild}</SliderThumb>
    </ChakraSlider>
  );
}
