import videojs from "video.js";
import Player from "video.js/dist/types/player";
import { messages, RPCRequests } from "@/modules/communication";
import commEmitter, { dataSyncEmitter } from "@/modules/events/emitter";
import { isPairedDevice } from "@/utils";
import { addGuard } from "@/utils";
import { viewerStore } from "viewer/store/viewer";

export default function createTimelineController(rpc: RPC, serviceWorker: SW) {
  let videoEl: HTMLVideoElement | null = null;
  let playerRef: Player | null = null;
  let didAskForEvents = false;
  let currentUpdateNumber: number | null = null;

  const createPlayer = async () => {
    const container = document.querySelector("#playlist_container");
    try {
      if ((playerRef && !playerRef.isDisposed()) || !container) {
        log.warn("Player was not disposed or container is 'undefined' in 'start'", container, playerRef);
        return;
      }

      videoEl = document.createElement("video");
      videoEl.id = "playlist_video";
      videoEl.classList.add("video-js", "vjs-default-skin");
      videoEl.preload = "auto";
      videoEl.muted = isDev;
      videoEl.controls = isDev;
      videoEl.setAttribute("data-setup", "{}");
      videoEl.onerror = onError;
      videoEl.onplaying = () => dataSyncEmitter.emit("timeline-playing");
      videoEl.onpause = () => dataSyncEmitter.emit("timeline-pause");
      videoEl.addEventListener("timeupdate", () => dataSyncEmitter.emit("timeline-ready", videoEl?.currentTime), {
        once: true
      });
      container.prepend(videoEl);

      playerRef = videojs(videoEl, { liveui: true });
      playerRef.src({
        src: "/playlist.m3u8",
        type: "application/x-mpegURL"
      });
      dataSyncEmitter.emit("timeline-video-element-creation", videoEl, playerRef);

      await playerRef.play();
    } catch (err) {
      log.err("Error calling 'start'", err);
    }
  };

  const start = async () => {
    await serviceWorker.start();
    await createPlayer();
  };

  const destroy = async () => {
    if (!playerRef) {
      log.warn("Called 'destroy' without calling 'start' first");
    } else {
      try {
        playerRef.dispose();
        didAskForEvents = false;
        currentUpdateNumber = null;
        await serviceWorker.unregister();
        await stopEventUpdatesFromCamera();
      } catch (err) {
        log.err(err);
      }
    }
  };

  const onError = (e: string | Event) => {
    log.err("Player error, about to destroy itself", e);
    dataSyncEmitter.emit("timeline-player-error");
  };

  const stopEventUpdatesFromCamera = async () => {
    const jid = viewerStore().selectedCamera.jid;
    if (!jid) return;
    const request = RPCRequests.StopCameraHistoryReplayEvents.create();
    await rpc.call(request, jid);
  };

  const checkUpdateNumber = (updateNumber: number) => {
    if (!currentUpdateNumber) {
      currentUpdateNumber = updateNumber;
      return true;
    } else if (updateNumber !== currentUpdateNumber + 1) {
      log.warn("Update number not OK", updateNumber, currentUpdateNumber);
      reloadEvents();
      return false;
    }
    currentUpdateNumber = updateNumber;
    return true;
  };

  const reloadEvents = async () => {
    const jid = viewerStore().selectedCamera.jid;
    if (!jid) return;
    try {
      const request = RPCRequests.ReloadCameraHistoryReplayEvents.create();
      const { events, updateNumber } = (await rpc.call(request, jid)) as CameraHistoryReplayEventsResponse;
      currentUpdateNumber = updateNumber;
      dataSyncEmitter.emit("timeline-events-reload", filterUnsupportedEvents(events));
    } catch (err) {
      log.err(err);
    }
  };

  const onEventUpdate = (update: CameraHistoryReplayEventUpdate, from: string) => {
    if (!isPairedDevice(from)) return;
    const { event, updateNumber, updateType } = update;
    const isOk = checkUpdateNumber(updateNumber);
    const isSupported = isSupportedEvent(event);
    if (!isOk || !isSupported) return;

    if (updateType === "CREATE") dataSyncEmitter.emit("timeline-event-create", event);
    if (updateType === "UPDATE") dataSyncEmitter.emit("timeline-event-update", event);
  };

  const onTimelineRender = () => {
    if (didAskForEvents) return;
    didAskForEvents = true;
    askForEvents();
  };

  const onShouldPlay = () => addGuard(() => playerRef!.play(), { onError: (err) => log.err(err) });
  const onShouldPause = () => addGuard(() => playerRef!.pause(), { onError: (err) => log.err(err) });

  const onPlayTimelineEvent = async (payload: { onComplete: Cb }) => {
    await onShouldPause();
    await serviceWorker.turnOff();
    payload.onComplete();
  };

  const onStopTimelineEvent = async () => {
    await serviceWorker.turnOn();
    await onShouldPlay();
  };

  const startListeningForUpdates = () => {
    commEmitter.on(messages.CameraHistoryReplayEventUpdate.name, onEventUpdate);
    dataSyncEmitter.on("timeline-render", onTimelineRender);
    dataSyncEmitter.on("timeline-should-play", onShouldPlay);
    dataSyncEmitter.on("timeline-should-pause", onShouldPause);
    dataSyncEmitter.on("play-timeline-event", onPlayTimelineEvent);
    dataSyncEmitter.on("stop-timeline-event", onStopTimelineEvent);
  };

  const askForEvents = async () => {
    const jid = viewerStore().selectedCamera.jid;
    if (!jid) return;
    log.info("About to ask for events");
    const request = RPCRequests.StartCameraHistoryReplayEvents.create();
    const { events, updateNumber } = (await rpc.call(request, jid)) as CameraHistoryReplayEventsResponse;
    currentUpdateNumber = updateNumber;
    dataSyncEmitter.emit("timeline-events-load", filterUnsupportedEvents(events));
  };

  const filterUnsupportedEvents = (events: CameraEvent[]) =>
    events.filter((e) => e.type !== "CONNECT" && e.type !== "DISCONNECT");
  const isSupportedEvent = (event: CameraEvent) => event.type === "MOTION" || event.type === "NOISE";

  startListeningForUpdates();
  serviceWorker.unregister();
  return {
    startTimelinePlayer: start,
    destroyTimelinePlayer: destroy
  };
}
