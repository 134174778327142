import { useCallback, useEffect, useState } from "react";
import { AspectRatio, Box } from "@chakra-ui/react";
import Close from "assets/icons/close_large.svg?react";
import { t } from "@/utils/translate";
import { dataSyncEmitter } from "@/modules/events/emitter";
import Modal from "@/components/Modal";
import IconButton from "@/components/IconButton";
import { useControls } from "@/hooks";
import EventDetailContent from "viewer/modules/activityLog/components/EventDetail/EventDetailContent";
import { getCameraHistoryEventByUniqueId } from "viewer/modules/cloud/media";
import { useViewer } from "viewer/store/viewer";
import { useActivityLog } from "viewer/store/activityLog";

export default function EventPlayerModal() {
  const modal = useControls();
  const [loading, setLoading] = useState(false);
  const { event, setEvent } = useActivityLog((s) => ({ event: s.playerEvent, setEvent: s.setPlayerEvent }));
  const selectedCamera = useViewer((s) => s.selectedCamera);

  const handleEventPlay = useCallback(
    async ({ uniqueId }: { uniqueId: string }) => {
      if (!selectedCamera.jid) return;
      setLoading(true);
      modal.open();
      const parseEvent = await getCameraHistoryEventByUniqueId({ uniqueId, jid: selectedCamera.jid });
      if (parseEvent) setEvent(parseEvent);
      dataSyncEmitter.emit("play-timeline-event", { onComplete: () => setLoading(false) });
    },
    [modal, selectedCamera.jid, setEvent]
  );

  const handleClose = useCallback(() => {
    dataSyncEmitter.emit("stop-timeline-event");
    setEvent(null);
    setLoading(false);
    modal.close();
  }, [modal, setEvent]);

  useEffect(() => dataSyncEmitter.on("select-timeline-event", handleEventPlay), [handleEventPlay]);

  return (
    <Modal isOpen={modal.isOpen} onClose={handleClose} modalBodyProps={{ p: 0 }}>
      <Box pos="relative">
        <IconButton
          icon={Close}
          tooltip={{ label: t("buttons.close") }}
          bg="rgba(68, 70, 79, 0.7)"
          props={{
            pos: "absolute",
            right: "24",
            top: "24",
            zIndex: 2
          }}
          onClick={handleClose}
        />
        <Box>
          {!loading && event ? (
            <EventDetailContent />
          ) : (
            <Box borderRadius="1rem">
              <AspectRatio ratio={16 / 9} w="100%" borderRadius="1rem">
                <Box py="1rem" />
              </AspectRatio>
              <Box h="168px" borderBottomRadius="1rem" w="100%" />
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
}
