import { useEffect, useRef, useState } from "react";
import { Box, Button, ButtonGroup, Flex, Text } from "@chakra-ui/react";
import { getCameraDevices } from "@/store/devices/selectors";
import { useDevices } from "@/store/devices";
import { t } from "@/utils";
import Time from "@/lib/Time";
import { isEmptyArray } from "@/utils";
import { useControls } from "@/hooks";
import ModalManager from "@/lib/ModalManager";
import { EventIcons, EventColors, EventStringKeys, EventValuesArr } from "@/enums/events";
import { CameraIcons, CameraColors } from "@/enums/camera";
import { useButtonStateColors } from "@/theme/utils/getButtonStateColor";
import IconButton from "@/components/IconButton";
import Popover from "@/components/Popover";
import Circle from "@/components/Circle";
import Icon from "@/components/Icon";
import { useEventFilters } from "viewer/store/eventFilters";
import { getHasActiveFilters } from "viewer/store/eventFilters/selectors";
import Filter from "assets/icons/filter_outlined.svg?react";
import ErrorIcon from "assets/icons/error.svg?react";
import ActivityLogController from "../ActivityLogController";
import FilterItem from "./FilterItem";
import DateInput from "./DateInput";

export default function FilterSelect() {
  const controls = useControls();
  const cameraDevices = useDevices(getCameraDevices);
  const eventFilters = useEventFilters();

  const { setCameraFilter, setTypeFilter, setDateFilter, activeConfigKey, filterConfigs } = eventFilters;

  const activeConfig = filterConfigs[activeConfigKey];

  const { active: dotActiveColor, hover: dotHoverColor } = useButtonStateColors("on-surface", "surface");
  const hasActiveFilters = getHasActiveFilters(eventFilters);
  const [cameraFilterDraft, setCameraFilterDraft] = useState<FilterConfig["cameras"]>({});
  const [eventTypeFilterDraft, setEventTypeFilterDraft] = useState<FilterConfig["types"]>({});

  const [invalidDate, setInvalidDate] = useState(false);
  const [hasDateSet, setDateSet] = useState(false);

  const startDateInputRef = useRef<HTMLInputElement>(null);
  const endDateInputRef = useRef<HTMLInputElement>(null);
  const [startDateInputOpened, setStartDateInputOpened] = useState(false);
  const [endDateInputOpened, setEndDateInputOpened] = useState(false);

  useEffect(() => {
    if (controls.isOpen) {
      if (activeConfig.date.start) {
        setStartDateInputOpened(true);
        if (startDateInputRef.current) startDateInputRef.current.value = activeConfig.date.start;
        setDateSet(true);
      }
      if (activeConfig.date.end) {
        setEndDateInputOpened(true);
        if (endDateInputRef.current) endDateInputRef.current.value = activeConfig.date.end;
        setDateSet(true);
      }
    } else {
      setEndDateInputOpened(false);
      setStartDateInputOpened(false);
      setDateSet(false);
    }
  }, [activeConfig.date.end, activeConfig.date.start, controls.isOpen]);

  const getUpdatedDraft = (item: string, collection: { [key: string]: string }) => {
    const draft = { ...collection };
    if (draft[item]) delete draft[item];
    else draft[item] = item;
    return draft;
  };
  const updateCameraFilterDraft = (item: string) => {
    const cameraFilters = getUpdatedDraft(item, cameraFilterDraft);
    setCameraFilterDraft(cameraFilters);
  };
  const updateEventTypeFilterDraft = (item: string) => {
    const eventTypeFilters = getUpdatedDraft(item, eventTypeFilterDraft);
    setEventTypeFilterDraft(eventTypeFilters);
  };

  const applyFilters = () => {
    const isValid = checkAndApplyDateFilters();
    if (isValid) {
      if (activeConfigKey === "fromDashboard") setCameraFilter(cameraFilterDraft);
      setTypeFilter(eventTypeFilterDraft);
      setInvalidDate(false);
      ActivityLogController.downloadEvents();
      controls.close();
    } else {
      setInvalidDate(true);
    }
  };

  const clearDraft = () => {
    setCameraFilterDraft({});
    setEventTypeFilterDraft({});
    resetDateRefs();
    setDateSet(false);
    setInvalidDate(false);
  };
  const resetDateRefs = () => {
    if (startDateInputRef.current) startDateInputRef.current.value = "";
    if (endDateInputRef.current) endDateInputRef.current.value = "";
    setStartDateInputOpened(false);
    setEndDateInputOpened(false);
  };
  const checkAndApplyDateFilters = () => {
    const startDateVal = startDateInputRef.current?.value;
    const endDateVal = endDateInputRef.current?.value;

    if (!startDateVal && !endDateVal) {
      setDateFilter({
        start: null,
        end: null
      });
      return true;
    }
    if (startDateVal && endDateVal) {
      if (new Date(startDateVal) > new Date(endDateVal)) {
        return false;
      }
    }
    if (endDateVal && new Date(endDateVal) > new Date())
      endDateInputRef.current!.value = Time.formatDate(new Date(), "YYYY-MM-DD");

    setDateFilter({
      start: startDateInputRef.current?.value || null,
      end: endDateInputRef.current?.value || null
    });
    return true;
  };

  const makeDraft = () => {
    setCameraFilterDraft(activeConfig.cameras);
    setEventTypeFilterDraft(activeConfig.types);
  };

  const open = () => {
    makeDraft();
    controls.open();
    ModalManager.add();
  };

  const close = () => {
    controls.close();
    ModalManager.remove();
    clearDraft();
  };
  const checkDraft = () => {
    setInvalidDate(false);
    if (startDateInputRef.current?.value || endDateInputRef.current?.value) {
      setDateSet(true);
    } else setDateSet(false);
  };

  const hasDraft =
    (!isEmptyArray(Object.keys(cameraFilterDraft)) && activeConfigKey === "fromDashboard") ||
    !isEmptyArray(Object.keys(eventTypeFilterDraft));

  return (
    <Popover
      popoverProps={{
        onOpen: open,
        onClose: close,
        computePositionOnMount: true,
        closeOnBlur: false,
        isOpen: controls.isOpen,
        placement: "bottom-end",
        preventOverflow: true,
        boundary: "scrollParent"
      }}
      contentProps={{
        maxW: "400px",
        overflowY: "auto",
        px: "1.5rem",
        pb: "1rem",
        pt: "0.75rem",
        maxH: "70vh"
      }}
      title={t("filter.title")}
      body={
        <>
          <Box mt="0.75rem">
            {(hasDraft || hasDateSet) && (
              <Button
                px="1rem"
                py="0.75rem"
                variant="text"
                position="absolute"
                top="0.5rem"
                right="0.25rem"
                onClick={clearDraft}
                fontSize="16px"
                letterSpacing="0.5px"
              >
                {t("clear.title")}
              </Button>
            )}
            <Flex rowGap="1.5rem" flexDir="column">
              {activeConfig.key !== "fromCamera" && cameraDevices.length > 0 && (
                <Box>
                  <Text mb="12px" variant="label" color="on-surface">
                    {t("cameras.title")}
                  </Text>
                  <Flex flexWrap="wrap" rowGap="0.75rem" columnGap="8px">
                    {cameraDevices.map(({ name, objectId, icon, color }) => (
                      <FilterItem
                        label={name}
                        key={objectId}
                        isActive={Boolean(cameraFilterDraft[objectId])}
                        onClick={() => updateCameraFilterDraft(objectId)}
                        icon={CameraIcons[icon].icon}
                        iconFill={CameraColors[color].value}
                        iconSize="24"
                        props={{ maxW: "100%" }}
                        textProps={{
                          maxW: "100%",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap"
                        }}
                      />
                    ))}
                  </Flex>
                </Box>
              )}
              <Box>
                <Text mb="0.75rem" variant="label" color="on-surface">
                  {t("eventType")}
                </Text>
                <Flex flexWrap="wrap" rowGap="0.75rem" columnGap="8px">
                  {EventValuesArr.slice(0, 2).map((eventType) => (
                    <FilterItem
                      label={t(EventStringKeys[eventType])}
                      key={eventType}
                      isActive={Boolean(eventTypeFilterDraft[eventType])}
                      onClick={() => updateEventTypeFilterDraft(eventType)}
                      icon={EventIcons[eventType]}
                      iconFill={EventColors[eventType].primary}
                      iconSize="24px"
                    />
                  ))}
                </Flex>
              </Box>
              <Box>
                <Text mb="0.75rem" variant="label" color="on-surface">
                  {t("date")}
                </Text>
                <Flex flexWrap="wrap" columnGap="0.5rem" alignItems="center">
                  <DateInput
                    title={t("startDate")}
                    inputProps={{ bg: "transparent" }}
                    ref={startDateInputRef}
                    invalid={invalidDate}
                    checkDraft={checkDraft}
                    isOpen={startDateInputOpened}
                    initValue={activeConfig.date.start}
                    setIsOpen={() => setStartDateInputOpened(true)}
                  />
                  -
                  <DateInput
                    title={t("endDate")}
                    inputProps={{ bg: "transparent" }}
                    ref={endDateInputRef}
                    invalid={invalidDate}
                    checkDraft={checkDraft}
                    initValue={activeConfig.date.end}
                    isOpen={endDateInputOpened}
                    setIsOpen={() => setEndDateInputOpened(true)}
                  />
                </Flex>
                <Box h="28px" mt="0.5rem">
                  {invalidDate && (
                    <Flex alignItems="center">
                      <Icon icon={ErrorIcon} size="16" fill="error" />
                      <Text variant="label" ml="0.25rem">
                        {t("invalidDateCombination")}
                      </Text>
                    </Flex>
                  )}
                </Box>
              </Box>
            </Flex>
          </Box>
        </>
      }
      footer={
        <ButtonGroup display="flex" justifyContent="flex-end" mt="0.25rem">
          <Button px="1rem" py="0.75rem" variant="textFixedSmall" onClick={close}>
            {t("buttons.cancel")}
          </Button>
          <Button px="1.5rem" py="0.75rem" variant="filledFixedSmall" onClick={applyFilters}>
            {t("buttons.done")}
          </Button>
        </ButtonGroup>
      }
    >
      <Box
        pos="relative"
        borderRadius="100%"
        _hover={{
          ".filter-dot": {
            borderColor: dotHoverColor
          }
        }}
        _active={{
          ".filter-dot": {
            borderColor: dotActiveColor
          }
        }}
      >
        <IconButton fill="on-surface" bg="surface" icon={Filter} tooltip={{ label: t("filter.title") }} />
        {hasActiveFilters && (
          <Circle
            bg="primary"
            size="10px"
            props={{
              pointerEvents: "none",
              className: "filter-dot",
              transition: "border-color 0.3s",
              border: "2px solid",
              borderColor: "surface",
              pos: "absolute",
              right: "3px",
              top: "4px"
            }}
          />
        )}
      </Box>
    </Popover>
  );
}
