import { Box } from "@chakra-ui/react";

export default function Gradient() {
  return (
    <Box
      h="227px"
      w="100%"
      pos="absolute"
      bottom={0}
      zIndex={-1}
      background="linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%)"
    />
  );
}
